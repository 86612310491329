if ("NodeList" in window && !NodeList.prototype.forEach) {
  console.info("polyfill for IE11");
  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;
    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}

function msieversion() {
  var ua = window.navigator.userAgent;
  var msie = ua.indexOf("MSIE ");
  return msie > 0;
}

var url_string = window.location;
var url = new URL(url_string);
var passedParameter = url.searchParams.get("direct");
if (passedParameter) {
  startFrom360 = false;
}
var outerLink;
outerLink = url.searchParams.get("link");

var BodyToRun = {};

if (passedParameter) {
  //add waiting screen
  var coverElement = document.createElement("DIV");
  coverElement.style.backgroundColor = "black";
  coverElement.style.height = "100%";
  coverElement.style.width = "100%";
  coverElement.style.zIndex = "100";
  //var body = querySelector("body")
  document.firstElementChild.appendChild(coverElement);
  //setupPanolens({cardboard:mobilecheck()});
  BodyToRun.runMe = function () {
    coverElement.parentElement.removeChild(coverElement);
    setupPanolens({ cardboard: mobilecheck() });
    setupPanolens(startFrom360 ? false : { cardboard: mobilecheck() });
    requestFullScreen(document.body);
    if (startFrom360 && mobilecheck()) {
      viewer.widget.dispatchEvent({
        type: "panolens-viewer-handler",
        method: "enableControl",
        data: PANOLENS.Controls.DEVICEORIENTATION,
      });
    }
  };
}

function RunAfterFonts() {
  Object.keys(BodyToRun).map(function (value) {
    if (typeof BodyToRun[value] === "function") {
      BodyToRun[value].call();
    }
  });
  /*
  Object.values(BodyToRun).map(function(value){
    if(typeof value === 'function') {
        value.call();
    }
  });*/
}

function iOSversion() {
  if (/iP(hone|od|ad)/.test(navigator.platform)) {
    // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
    var v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
    return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
  }
}
var nButton;
function checkDeviceMotionIsTurnedOn() {
  if (
    typeof DeviceMotionEvent !== "undefined" &&
    typeof DeviceMotionEvent.requestPermission === "function"
  ) {
    DeviceMotionEvent.requestPermission()
      .then(function (permissionState) {
        if (permissionState === "granted") {
          window.addEventListener("devicemotion", function () {});
        }
      })
      .catch(function () {
        // var nButton = $('<div class="confirm_text></div>').append($('<button class="confirm_button"></button>'));
        if (nButton) {
          return;
        }
        nButton = $(
          '<div style="display: flex;position: fixed;top: 0;bottom: 0;left: 0;right: 0;justify-content: center;align-items: center;" class="confirm_text"></div>'
        ).append(
          $(
            '<button style="font-size: 25px;background-color: #ffbd00; color:#333; letter-spacing: 1px; font-weight:500;  border: 1px solid #333;padding: 10px 40px;" class="confirm_button">START</button>'
          )
        );
        console.log(nButton);
        $(document.body).append(nButton);
        nButton.on("click", function () {
          iOSMotion();
          nButton.remove();
        });
      });
  } else {
    var ver = iOSversion();
    if (ver && ver.length > 0 && ver[0] === 12) {
      var deviceMotionEvt;

      setTimeout(function () {
        if (!deviceMotionEvt) {
          var textAlert =
            "Enable device orientation in: Settings -> Safari -> Motion & Orientation Access.";
          htmlTexts.forEach(function (entry) {
            if ("foot-ios" === entry.id) {
              textAlert = entry[getLanguage()];
            }
          });
          alert(textAlert);
        }
      }, 3000);

      window.addEventListener("devicemotion", function (event) {
        deviceMotionEvt = event;
      });
    }
  }
}

function iOSMotion() {
  console.log("I am the new ios");
  if (typeof DeviceMotionEvent.requestPermission === "function") {
    console.log("I am the new ios");
    DeviceMotionEvent.requestPermission()
      .then(function (permissionState) {
        if (permissionState === "granted") {
          window.addEventListener("devicemotion", function () {});
        }
      })
      .catch(console.error);
  } else {
  }
}

document.addEventListener("DOMContentLoaded", function () {
  var dropdownWasClicked = false;
  var intTimeout = -1;
  function hoverTheList() {
    intTimeout = setTimeout(function () {
      dropdownWasClicked = true;
    }, 100);
    dropdown.classList.add("entered");
  }

  function unhoverTheList() {
    clearTimeout(intTimeout);
    dropdownWasClicked = false;
  }
  var dropdown = document.querySelector(".dropdown");
  if (!dropdown) {
    console.log("no language");
    return;
  }
  var languages = dropdown.querySelectorAll("div");

  dropdown.addEventListener("mouseover", function () {
    hoverTheList();
  });

  dropdown.addEventListener("mouseleave", function () {
    this.classList.remove("entered");
    unhoverTheList();
  });

  languages.forEach(function (elem) {
    if (getBrowserLanguage() === elem.dataset.ln) {
      dropdown.insertBefore(
        elem,
        dropdown.hasChildNodes() ? dropdown.childNodes[0] : null
      );
    }

    elem.addEventListener("click", function (event) {
      if (!dropdownWasClicked) {
        hoverTheList();
        return;
      }
      var lang = this.dataset.ln;
      setLanguage(lang, true);
      dropdown.insertBefore(
        this,
        dropdown.hasChildNodes() ? dropdown.childNodes[0] : null
      );
      dropdown.classList.remove("entered");
      unhoverTheList();
      // dropdown.style.pointerEvents = "none";
    });
  });

  if (mobilecheck()) {
    dropdown.addEventListener("touchstart", function (event) {
      $(".container-wrapper").animate(
        { scrollTop: document.body.scrollHeight + 9000 },
        1000
      );
    });
  }
});
var nElement;
function ManualFullscreen() {
  if (window.screen.width > window.innerHeight && !nElement) {
    viewer.OrbitControls.disconnectTouch &&
      viewer.OrbitControls.disconnectTouch();
    viewer.DeviceOrientationControls.disconnetTouch &&
      viewer.DeviceOrientationControls.disconnetTouch();
    $("body").animate({ scrollTop: 0 }, 200);

    viewer.OrbitControls.disconnectTouch();
    viewer.DeviceOrientationControls.disconnetTouch();
    $(document.body).css("height", "140%");
    $(document.documentElement).css("overflow-y", "scroll");

    //var nElement = document.createElement("DIV");
    var nElement = $('<div class="iphone_swiper"></div>').append(
      $('<div class="phone_hand"></div>')
    );
    //var childElement = $('<div class="phone_hand"></div>')
    //nElement.classList.add('iphone_swiper');
    $(document.body).append(nElement);
    $(nElement).fadeIn();
    function CallWhenDown(e) {
      // console.log("I try to scroll: " + e);
      // if($(window).scrollTop() + $(window).height() == $(document).height())
      {
        $(nElement).fadeOut(1000, function () {
          $(nElement).remove();
          // $(document.body).css('height',"100%");
          // $(document.documentElement).css('overflow-y', 'auto')
        });
        $(window).off("scroll", CallWhenDown);
      }
    }
    $(window).on("scroll", CallWhenDown);
  }
}

function IphonesSize() {
  var width = Math.min(
    document.documentElement.clientWidth,
    document.documentElement.clientHeight
  );
  var height = Math.max(
    document.documentElement.clientWidth,
    document.documentElement.clientHeight
  );
  var widthS = Math.min(window.screen.width, window.screen.height);
  var heightS = Math.max(window.screen.width, window.screen.height);
  var finded = iphones.find(function (elem) {
    return elem.height === heightS && elem.width === widthS;
  });
  if (finded) {
    console.log(finded.name);
    return finded.val;
  }
  console.log("not found" + widthS + ">>" + heightS);
  return 6.1;
}
var iphones = [
  { name: "iPhone 15 Pro Max", width: 430, height: 932, val: 6.7 },
  { name: "iPhone 15 Pro", width: 393, height: 852, val: 6.1 },
  { name: "iPhone 15 Plus", width: 430, height: 932, val: 6.7 },
  { name: "iPhone 15", width: 393, height: 852, val: 6.1 },
  { name: "Apple Watch Ultra 2", width: 205, height: 251, val: 1.919 },
  { name: "Apple Watch Series 9 Large", width: 198, height: 242, val: 1.901 },
  { name: "Apple Watch Series 9 Small", width: 176, height: 215, val: 1.691 },
  { name: "iPad Pro (6th gen 12.9)", width: 1024, height: 1366, val: 12.9 },
  { name: "iPad Pro (6th gen 11)", width: 834, height: 1194, val: 11 },
  { name: "iPad 10th gen", width: 820, height: 1180, val: 10.9 },
  { name: "iPhone 14 Plus", width: 428, height: 926, val: 6.7 },
  { name: "Apple Watch Ultra", width: 205, height: 251, val: 1.919 },
  { name: "iPhone 14 Pro Max", width: 430, height: 932, val: 6.7 },
  { name: "iPhone 14 Pro", width: 393, height: 852, val: 6.1 },
  { name: "iPhone 14", width: 390, height: 844, val: 6.1 },
  { name: "Apple Watch Series 8 Large", width: 198, height: 242, val: 1.901 },
  { name: "Apple Watch Series 8 Small", width: 176, height: 215, val: 1.691 },
  { name: "Apple Watch SE (2nd) Large", width: 184, height: 224, val: 1.757 },
  { name: "Apple Watch SE (2nd) Small", width: 162, height: 197, val: 1.549 },
  { name: "iPhone SE 3rd gen", width: 375, height: 667, val: 4.7 },
  { name: "iPad Air (5th gen)", width: 820, height: 1180, val: 10.9 },
  { name: "Apple Watch Series 7 Large", width: 198, height: 242, val: 1.901 },
  { name: "Apple Watch Series 7 Small", width: 176, height: 215, val: 1.691 },
  { name: "iPad Mini (6th gen)", width: 744, height: 1133, val: 8.3 },
  { name: "iPhone 13", width: 390, height: 844, val: 6.06 },
  { name: "iPhone 13 mini", width: 375, height: 812, val: 5.42 },
  { name: "iPhone 13 Pro Max", width: 428, height: 926, val: 6.68 },
  { name: "iPhone 13 Pro", width: 390, height: 844, val: 6.06 },
  { name: "iPad 9th gen", width: 810, height: 1080, val: 10.2 },
  { name: "iPad Pro (5th gen 12.9)", width: 1024, height: 1366, val: 12.9 },
  { name: "iPad Pro (5th gen 11)", width: 834, height: 1194, val: 11 },
  { name: "iPad Air (4th gen)", width: 820, height: 1180, val: 10.9 },
  { name: "iPhone 12", width: 390, height: 844, val: 6.06 },
  { name: "iPhone 12 mini", width: 375, height: 812, val: 5.42 },
  { name: "iPhone 12 Pro Max", width: 428, height: 926, val: 6.68 },
  { name: "iPhone 12 Pro", width: 390, height: 844, val: 6.06 },
  { name: "iPad 8th gen", width: 810, height: 1080, val: 10.2 },
  { name: "Apple Watch Series 6 Large", width: 184, height: 224, val: 1.757 },
  { name: "Apple Watch Series 6 Small", width: 162, height: 197, val: 1.549 },
  { name: "Apple Watch SE (1st) Large", width: 184, height: 224, val: 1.757 },
  { name: "Apple Watch SE (1st) Small", width: 162, height: 197, val: 1.549 },
  { name: "iPhone SE 2nd gen", width: 375, height: 667, val: 4.7 },
  { name: "iPad Pro (4th gen 12.9)", width: 1024, height: 1366, val: 12.9 },
  { name: "iPad Pro (4th gen 11)", width: 834, height: 1194, val: 11 },
  { name: "iPad 7th gen", width: 810, height: 1080, val: 10.2 },
  { name: "iPhone 11 Pro Max", width: 414, height: 896, val: 6.46 },
  { name: "iPhone 11 Pro", width: 375, height: 812, val: 5.85 },
  { name: "iPhone 11", width: 414, height: 896, val: 6.1 },
  { name: "Apple Watch Series 5 Large", width: 184, height: 224, val: 1.757 },
  { name: "Apple Watch Series 5 Small", width: 162, height: 197, val: 1.549 },
  { name: "iPod touch 7th gen", width: 320, height: 568, val: 4 },
  { name: "iPad Mini (5th gen)", width: 768, height: 1024, val: 7.9 },
  { name: "iPad Air (3rd gen)", width: 834, height: 1112, val: 10.5 },
  { name: "iPad Pro (3rd gen 12.9)", width: 1024, height: 1366, val: 12.9 },
  { name: "iPad Pro (3rd gen 11)", width: 834, height: 1194, val: 11 },
  { name: "iPhone XR", width: 414, height: 896, val: 6.06 },
  { name: "iPhone XS Max", width: 414, height: 896, val: 6.46 },
  { name: "iPhone XS", width: 375, height: 812, val: 5.85 },
  { name: "Apple Watch Series 4 Large", width: 184, height: 224, val: 1.757 },
  { name: "Apple Watch Series 4 Small", width: 162, height: 197, val: 1.549 },
  { name: "iPad 6th gen", width: 768, height: 1024, val: 9.7 },
  { name: "iPhone X", width: 375, height: 812, val: 5.85 },
  { name: "iPhone 8 Plus", width: 414, height: 736, val: 5.5 },
  { name: "iPhone 8", width: 375, height: 667, val: 4.7 },
  { name: "Apple Watch Series 3 Large", width: 156, height: 195, val: 1.337 },
  { name: "Apple Watch Series 3 Small", width: 156, height: 170, val: 1.337 },
  { name: "iPad Pro (2nd gen 12.9)", width: 1024, height: 1366, val: 12.9 },
  { name: "iPad Pro (2nd gen 10.5)", width: 834, height: 1112, val: 10.5 },
  { name: "iPad 5th gen", width: 768, height: 1024, val: 9.7 },
  { name: "iPhone 7 Plus", width: 414, height: 736, val: 5.5 },
  { name: "iPhone 7", width: 375, height: 667, val: 4.7 },
  { name: "Apple Watch Series 2 Large", width: 156, height: 195, val: 1.337 },
  { name: "Apple Watch Series 2 Small", width: 156, height: 170, val: 1.337 },
  { name: "Apple Watch Series 1 Large", width: 156, height: 195, val: 1.337 },
  { name: "Apple Watch Series 1 Small", width: 156, height: 170, val: 1.337 },
  { name: "iPhone SE 1st gen", width: 320, height: 568, val: 4 },
  { name: "iPad Pro (1st gen 9.7”)", width: 768, height: 1024, val: 9.7 },
  { name: "iPad Pro (1st gen 12.9)", width: 1024, height: 1366, val: 12.9 },
  { name: "iPhone 6s Plus", width: 414, height: 736, val: 5.5 },
  { name: "iPhone 6s", width: 375, height: 667, val: 4.7 },
  { name: "iPad mini 4", width: 768, height: 1024, val: 7.9 },
  { name: "iPod touch 6th gen", width: 320, height: 568, val: 4 },
  {
    name: "Apple Watch 1st (Series 0) Large",
    width: 156,
    height: 195,
    val: 1.337,
  },
  {
    name: "Apple Watch 1st (Series 0) Small",
    width: 156,
    height: 170,
    val: 1.337,
  },
  { name: "iPad Air 2", width: 768, height: 1024, val: 9.7 },
  { name: "iPad mini 3", width: 768, height: 1024, val: 7.9 },
  { name: "iPhone 6 Plus", width: 414, height: 736, val: 5.5 },
  { name: "iPhone 6", width: 375, height: 667, val: 4.7 },
  { name: "iPad mini 2", width: 768, height: 1024, val: 7.9 },
  { name: "iPad Air", width: 768, height: 1024, val: 9.7 },
  { name: "iPhone 5C", width: 320, height: 568, val: 4 },
  { name: "iPhone 5S", width: 320, height: 568, val: 4 },
  { name: "iPad 4th gen", width: 768, height: 1024, val: 9.7 },
  { name: "iPad mini", width: 768, height: 1024, val: 7.9 },
  { name: "iPod touch 5th gen", width: 320, height: 568, val: 4 },
  { name: "iPhone 5", width: 320, height: 568, val: 4 },
  { name: "iPad 3rd gen", width: 768, height: 1024, val: 9.7 },
  { name: "iPhone 4S", width: 320, height: 480, val: 3.5 },
  { name: "iPad 2", width: 768, height: 1024, val: 9.7 },
  { name: "iPod touch 4th gen", width: 320, height: 480, val: 3.5 },
  { name: "iPhone 4", width: 320, height: 480, val: 3.5 },
  { name: "iPad 1st gen", width: 768, height: 1024, val: 9.7 },
  { name: "iPod touch 3rd gen", width: 320, height: 480, val: 3.5 },
  { name: "iPhone 3GS", width: 320, height: 480, val: 3.5 },
  { name: "iPod touch 2nd gen", width: 320, height: 480, val: 3.5 },
  { name: "iPhone 3G", width: 320, height: 480, val: 3.5 },
  { name: "iPod touch 1st gen", width: 320, height: 480, val: 3.5 },
  { name: "iPhone 1st gen", width: 320, height: 480, val: 3.5 },
];
